import Link from "@/src/common/components/Link"
import Image from "next/legacy/image"

import noElections from "../../../../public/assets/icons/elections/no-elections.png"
import { URLFactory } from "../../business-layer/factories/URLFactory"
import Typography from "../Typography"

const Placeholder = ({
  className: extraClassNames = ""
}: {
  className?: string
}) => (
  <div
    className={`w-full h-[325px] sm:h-[525px] flex flex-col justify-center bg-gray-100 rounded-lg ${extraClassNames}`}
  >
    <div className="m-auto w-8 h-8">
      <div
        className="w-full h-full spinner-border animate-spin inline-block border-4 rounded-full"
        role="status"
      />
    </div>
  </div>
)

Placeholder.NoElections = ({
  className: extraClassNames = ""
}: {
  className?: string
}) => (
  <div
    className={
      "w-full bg-gray-50 p-16 border border-gray-10 " + extraClassNames
    }
  >
    <div className="mx-auto w-[315px]">
      <div className="rounded-full w-14 h-14 mx-auto">
        <Image
          src={noElections.src}
          alt="No elections"
          width={18}
          height={18}
          layout="responsive"
        />
      </div>
      <Typography type="p18x" weight="medium" className="mt-6 text-center">
        No Elections
      </Typography>
      <Typography type="p18" className="mt-2 text-center text-gray-500">
        We currently do not have election updates for this state
      </Typography>
      <Link legacyBehavior href={URLFactory.myElections}>
        <a
          className={`block w-[190px] mx-auto mt-4 bg-black text-white text-center p-4 ${Typography.className(
            { type: "p15x" }
          )}`}
        >
          Explore my elections
        </a>
      </Link>
    </div>
  </div>
)

export default Placeholder
