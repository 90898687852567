import Link from "@/src/common/components/Link"
import { ElectionTime } from "@/src/common/types"
import { raceHasElectionInTime } from "@/src/common/utils"
import {
  HISTORICAL_RESULTS_START_YEAR,
  RACE_ADJECTIVE
} from "@/src/elections/business-layer/constants"
import { URLFactory } from "@/src/elections/business-layer/factories/URLFactory"
import { Race } from "@/src/elections/business-layer/types"
import classNames from "classnames"

import { UPCOMING_ELECTIONS_YEAR } from "../../business-layer/config"

type ElectionsDataTimelineProps = {
  title?: string
  timeline: ElectionTime[]
  race: Race
  year: string
  state?: string
  onClickYear?: (year: string) => void
}

const ElectionsDataTimeline = ({
  title,
  year,
  state,
  race,
  onClickYear,
  timeline
}: ElectionsDataTimelineProps) => (
  <div className="m-8">
    <h4>
      {title ? title : `Explore prior ${RACE_ADJECTIVE[race]} election results`}
    </h4>

    <div className="bg-[#ABACAE] h-[2px] flex justify-between items-center mt-14 mb-4 mx-5">
      {timeline
        .filter((time) => parseInt(time.year) >= HISTORICAL_RESULTS_START_YEAR)
        .map((time) => {
          const isSelectedYear =
            parseInt(year as string) === parseInt(time.year)
          const hasElection =
            time.year === UPCOMING_ELECTIONS_YEAR ||
            raceHasElectionInTime(time, race as Race, state)

          return (
            <Link
              legacyBehavior
              key={time.year}
              href={URLFactory.election(
                race as Race,
                time.year as string,
                state
              )}
            >
              <a
                className={classNames("relative", {
                  "pointer-events-none": !hasElection
                })}
                onClick={() => onClickYear?.(time.year)}
              >
                <span
                  className={classNames(
                    "inline-flex items-center absolute -translate-x-1/2 origin-left left-1/2 -top-[42px] h-6",
                    {
                      "text-stears_positive": isSelectedYear,
                      "text-gray-500":
                        (!isSelectedYear && !time.isGeneralElectionYear) ||
                        !hasElection,
                      "text-sm md:text-base": time.isGeneralElectionYear,
                      "text-[10px] md:text-sm font-light":
                        !time.isGeneralElectionYear
                    }
                  )}
                >
                  {time.year}
                </span>

                <div className="relative flex justify-center items-center">
                  <div
                    className={classNames("absolute border-1 rounded-full", {
                      "w-4 h-4": !time.isGeneralElectionYear,
                      "w-6 h-6": time.isGeneralElectionYear,
                      "border-stears_black": !isSelectedYear,
                      "border border-stears_positive bg-white": isSelectedYear
                    })}
                  />

                  <div
                    className={classNames("absolute rounded-full", {
                      "bg-stears_black": !isSelectedYear && hasElection,
                      "bg-gray-400": !isSelectedYear && !hasElection,
                      "bg-stears_positive": isSelectedYear,
                      "w-2 h-2": !time.isGeneralElectionYear,
                      "w-3 h-3": time.isGeneralElectionYear
                    })}
                  />
                </div>
              </a>
            </Link>
          )
        })}
    </div>
  </div>
)

export default ElectionsDataTimeline
