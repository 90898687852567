import Link from "@/src/common/components/Link"
import { ReactNode } from "react"

import Avatar from "../Avatar"
import Typography from "../Typography"

export type TableHeader<T> = {
  name: string
  keepIfEmpty?: boolean
  avatarField?: keyof T
  id: keyof T
  key: keyof T | ((c: T) => ReactNode)
}

export type TableProps<T extends { href?: string }> = {
  headers: Array<TableHeader<T>>
  rows: Array<T>
  idField: keyof T
  className?: string
  onClickRow?: (row: T) => void
}

function cellValue<T>(key: TableHeader<T>["key"], row: T): any {
  return typeof key === "function" ? key(row) : row[key]
}

function removeEmptyColumnHeaders<T>(
  headers: Array<TableHeader<T>>,
  rows: Array<T>
) {
  return headers.filter(
    ({ id, keepIfEmpty = true }) =>
      keepIfEmpty ||
      !rows.every((row) => [undefined, null, ""].includes(row[id] as any)) // not empty column
  )
}

export default function Table<T extends { href?: string }>({
  headers,
  rows,
  idField,
  onClickRow,
  className: extraClassNames = ""
}: TableProps<T>) {
  const filteredHeaders = removeEmptyColumnHeaders(headers, rows)
  const leftMostClassNames =
    "first:sticky first:left-0 first:border-r first:border-gray-200 max-w-[160px]"
  return (
    <div
      className={`border border-gray-200 overflow-scroll ${extraClassNames}`}
    >
      <table className="table-auto relative border-separate border-spacing-0 w-full">
        <thead>
          <tr>
            {filteredHeaders.map(({ name }, idx) => (
              <th
                className={`p-0 sticky top-0 z-10 first:z-20 ${leftMostClassNames} bg-gray-100`}
                key={name}
              >
                <Typography
                  type="p15x"
                  className={`p-3 md:p-4 whitespace-nowrap text-gray-800 ${
                    idx === 0 ? "text-left" : ""
                  }`}
                >
                  {name}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row: T, rowIdx) => (
            <tr key={JSON.stringify(row[idField])}>
              {filteredHeaders.map(({ name, key, avatarField }, idx) => {
                const value = cellValue(key, row)
                const avatarSrc: any = avatarField
                  ? row[avatarField]
                  : undefined
                const cell =
                  idx === 0 ? (
                    <div className="p-3 flex flex-row justify-left">
                      <Avatar
                        size="xs"
                        className="my-auto"
                        src={avatarSrc || undefined}
                      />
                      <Typography
                        type="p15"
                        className="ml-2 whitespace-nowrap text-gray-800 w-full overflow-x-scroll"
                      >
                        {value}
                      </Typography>
                    </div>
                  ) : typeof value === "string" ? (
                    <Typography
                      type="p15x"
                      className="p-3 whitespace-nowrap text-gray-800 text-center"
                    >
                      {value}
                    </Typography>
                  ) : (
                    <div className="flex justify-center">{value}</div>
                  )

                return (
                  <td
                    key={name}
                    className={`p-0 min-h-[52px] ${leftMostClassNames} bg-white ${
                      rowIdx === 0 ? "" : "border-t border-gray-200"
                    }`}
                  >
                    {row.href ? (
                      <Link legacyBehavior href={row.href || "#"}>
                        <a onClick={() => onClickRow?.(row)}>{cell}</a>
                      </Link>
                    ) : (
                      cell
                    )}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
