import { DATE_FORMAT } from "@/src/common/business-layer/constants"
import Link from "@/src/common/components/Link"
import { format } from "date-fns"

import KeyDate from "../KeyDate"
import Notice from "../Notice"
import Spinner from "../Spinner"
import Typography from "../Typography"

export type FollowCTA = {
  action: "follow" | "unfollow"
  link?: string
  loading?: boolean
  toggle?: (active?: boolean) => void
}

export type UpcomingElectionInfoProps = {
  title?: string
  notice?: string
  date?: string
  loading?: boolean
  following?: boolean
  onClickAction?: () => void
  links?: Array<{ href: string; title: string }>
  hideCountDown?: boolean
}

const UpcomingElectionInfo = ({
  title = "Upcoming Election",
  notice,
  date,
  links,
  following,
  onClickAction,
  loading,
  hideCountDown
}: UpcomingElectionInfoProps) => {
  const actionText = following ? "Unfollow" : "Follow"
  const ctaClassName =
    `rounded-full border border-black md:border-gray-600 px-4 py-2 h-8 md:py-3 md:h-10 font-medium md:font-normal whitespace-nowrap overflow-ellipsis ${Typography.className(
      { type: "p12x" }
    )}` +
    " " +
    (actionText === "Unfollow" ? "bg-black text-white" : "")

  return (
    <>
      <div className="border border-gray-10">
        <section className="py-6 px-4 flex flex-row justify-between">
          <div className="flex flex-col justify-between w-full">
            <Typography type="p15" weight="medium" className="md:hidden">
              {title}
            </Typography>
            <Typography type="p18x" weight="medium" className="-md:hidden">
              {title}
            </Typography>
            {date && (
              <Typography type="p15" className="text-gray-600 mt-2">
                {format(new Date(date), DATE_FORMAT.monthDayYear)}
              </Typography>
            )}
            {links && links.length !== 0 && (
              <section className="mt-2 flex flex-row w-full flex-wrap">
                {links.map((link) => (
                  <Link
                    legacyBehavior
                    key={link.href}
                    href={link.href}
                    passHref={true}
                  >
                    <a
                      data-testid={link.title}
                      className={`px-3 py-2 mt-2 h-8 first:ml-0 ml-1 rounded-full border border-black md:border-gray-600 ${Typography.className(
                        { type: "p12x" }
                      )}`}
                    >
                      {link.title}
                    </a>
                  </Link>
                ))}
              </section>
            )}
          </div>

          {loading ? (
            <div className={`${ctaClassName} flex flex-col justify-center`}>
              <Spinner />
            </div>
          ) : onClickAction ? (
            <button
              type="submit"
              data-testid="follow-unfollow-cta"
              onClick={onClickAction}
              className={ctaClassName}
            >
              {actionText}
            </button>
          ) : null}
        </section>
        {!hideCountDown && date ? <KeyDate date={date} /> : null}
      </div>
      {notice && (
        <Notice
          type="star"
          className="mt-4 bg-blue-50 px-4 py-5 rounded-lg"
          title="Attention"
        >
          {notice}
        </Notice>
      )}
    </>
  )
}

export default UpcomingElectionInfo
