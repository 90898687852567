import Image from "next/legacy/image"

import alert from "../../../../public/assets/icons/elections/alert.svg"
import star from "../../../../public/assets/icons/elections/star.svg"
import Typography from "../Typography"

type NoticeType = "alert" | "star"

export type NoticeProps = {
  children: string
  title?: string
  type?: NoticeType
  className?: string
}

const Notice = ({
  children,
  title,
  type = "alert",
  className: extraClassNames = ""
}: NoticeProps) => (
  <div className={extraClassNames}>
    <span className="h-5 w-5 inline-block align-top mt-1">
      <Image
        src={type === "star" ? star : alert}
        alt={type}
        height={18}
        width={18}
      />
    </span>
    <span className="inline-block ml-3 w-5/6 align-top">
      {title ? (
        <Typography type="p15" weight="medium" className="mb-3">
          Attention
        </Typography>
      ) : null}
      <Typography type="p12" className="md:hidden mt-1 text-gray-600">
        {children}
      </Typography>
      <Typography type="p15" className="-md:hidden mt-1 text-gray-600">
        {children}
      </Typography>
    </span>
  </div>
)

export default Notice
