import Link from "@/src/common/components/Link"
import { Tab } from "@headlessui/react"
import classNames from "classnames"
import { ReactElement, useEffect, useRef } from "react"

import Typography from "../Typography"

export type TabGroupProps<
  T extends { slug: string; href: string; title: string }
> = {
  tabProps: T[]
  className?: string
  selectedIndex?: number
  children?: (props: T) => ReactElement
}

const TabGroup = ({
  tabProps,
  children,
  selectedIndex,
  className: extraClassNames = ""
}: TabGroupProps<any>) => {
  const defaultClasses = "text-gray-600 border-b-2 border-transparent"
  const activeClasses = "text-black border-b-2 border-black"
  const selectedTab = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    selectedTab.current?.focus({ preventScroll: false })
  })

  return (
    <span className={extraClassNames}>
      <Tab.Group selectedIndex={selectedIndex}>
        <nav className="px-2 md:px-4 border-b border-gray-200 bg-white">
          <Tab.List className="whitespace-nowrap overflow-x-auto no-scrollbar">
            {tabProps.map((props, idx) => (
              <Tab
                key={props.slug}
                ref={selectedIndex === idx ? selectedTab : null}
                className="px-4 focus:outline-none"
              >
                {props.href ? (
                  <Link legacyBehavior href={props.href}>
                    <a>
                      <Typography
                        type="p15x"
                        className={
                          "pt-6 pb-5 " +
                          (selectedIndex === idx
                            ? activeClasses
                            : defaultClasses)
                        }
                      >
                        {props.title}
                      </Typography>
                    </a>
                  </Link>
                ) : (
                  <Typography
                    type="p15x"
                    className={classNames("pt-6 pb-5 text-gray-400", [
                      selectedIndex === idx ? activeClasses : defaultClasses
                    ])}
                  >
                    {props.title}
                  </Typography>
                )}
              </Tab>
            ))}
          </Tab.List>
        </nav>
        <Tab.Panels>
          {children
            ? tabProps.map((props) => (
                <Tab.Panel key={props.slug}>{children(props)}</Tab.Panel>
              ))
            : null}
        </Tab.Panels>
      </Tab.Group>
    </span>
  )
}

export default TabGroup
