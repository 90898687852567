import {
  SocialShareCard,
  SocialShareCardProps
} from "@/src/common/components/SocialShareCard/index"
import { SocialShareEventProps } from "@/src/elections/business-layer/types"
import classNames from "classnames"
import { useRouter } from "next/router"

const MobileSocialShare = ({
  pageTitle,
  pageURL,
  ...props
}: {
  pageTitle: string
  pageURL: string
  onClickShare?: (media: SocialShareEventProps["type"]) => void
  buttons?: SocialShareCardProps["buttons"]
}) => {
  const { pathname } = useRouter()
  return (
    <section
      className={classNames(
        "fixed right-2 lg:hidden z-[2010]",
        pathname.startsWith("/elections") ? "bottom-8" : "bottom-[230px]"
      )}
    >
      <SocialShareCard
        title={pageTitle}
        url={pageURL}
        {...props}
        verticalArrangement
      />
    </section>
  )
}

export default MobileSocialShare
