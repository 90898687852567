import { differenceInDays, intervalToDuration } from "date-fns"
import { nextTick } from "process"
import { useEffect, useState } from "react"

import Spinner from "../Spinner"
import Typography from "../Typography"

export type KeyDateProps = {
  date: string
  className?: string
}

const countdownTo = ({ start, end }: Interval): Array<string> => {
  const { hours, minutes, seconds } = intervalToDuration({ start, end })
  const days = differenceInDays(end, start)
  return [
    days + (days === 1 ? " day" : " days"),
    (hours || 0) + " hrs",
    (minutes || 0) + " min",
    (seconds || 0) + " sec"
  ]
}

const KeyDate = ({ date, className: extraClassNames = "" }: KeyDateProps) => {
  const dateInstance = new Date(date)
  const [currentDate, setCurrentDate] = useState<Date | null>(null)
  const dateCanAchieveCountDown = !(currentDate && dateInstance < currentDate)

  const updateCounter = () => void setCurrentDate(new Date())

  useEffect(() => {
    if (!dateCanAchieveCountDown) {
      return
    }

    if (typeof window !== "undefined") {
      nextTick(updateCounter)
    }

    const interval = setInterval(updateCounter, 1000)
    return () => clearInterval(interval)
  }, [date])

  const countdown = currentDate
    ? countdownTo({
        start: currentDate,
        end: dateCanAchieveCountDown ? dateInstance : currentDate
      })
    : []

  return (
    <article data-testid="key-date" className="bg-black">
      <ul
        className={`p-4 flex flex-row justify-between max-w-xs ${extraClassNames}`}
      >
        {countdown.length ? (
          countdown.map((count, idx) => (
            <li key={idx} className="first:ml-0">
              <Typography
                type="p15x"
                className="text-white text-nowrap text-ellipsis"
              >
                {count}
              </Typography>
            </li>
          ))
        ) : (
          <Spinner fill="white" />
        )}
      </ul>
    </article>
  )
}

export default KeyDate
