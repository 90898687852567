import Link from "@/src/common/components/Link"
import { ReactElement } from "react"
import tinycolor from "tinycolor2"

import { GENDER } from "../../business-layer/constants"
import {
  CandidateCampaignInfo,
  PartyDominanceInfo
} from "../../business-layer/types"
import Icon from "../Icon"
import Table, { TableHeader } from "../Table"
import Typography from "../Typography"

export type CandidateRow = {
  name: string
  party: string
  partyColor?: string
  href?: string
  gender: CandidateCampaignInfo["gender"]
  incumbent?: string | boolean
  image?: string
  region?: string
  votePercentage?: CandidateCampaignInfo["votePercentageFormatted"]
}

export type MapPopoverItem<T extends { href?: string }> = {
  title: string
  link: string
  rows: Array<T>
  tableHeaders?: Array<TableHeader<T>>
  emptyMessage?: string
  linkText?: string
  onClickClose?: () => void
  onClickItem?: (item: T) => void
  onClickLink?: () => void
}

export type MapPopoverProps<T extends { href?: string }> = MapPopoverItem<T> & {
  className?: string
}

export const PartyPill = ({
  party = "",
  partyColor
}: {
  party?: string
  partyColor?: string
}): ReactElement => (
  <Typography
    type="p12x"
    className={`my-auto inline-block rounded-full py-2 px-3 `}
    style={{
      color: partyColor,
      backgroundColor: partyColor
        ? tinycolor(partyColor).setAlpha(0.2).toRgbString()
        : undefined
    }}
  >
    {party?.toUpperCase()}
  </Typography>
)

export const YesNoPill = ({
  yesNo
}: {
  yesNo?: string | boolean
}): ReactElement => (
  <div
    className={`rounded-full px-3 py-[6px] my-auto inline-flex flex-row bg-gray-100`}
  >
    {yesNo === undefined ? (
      <Typography type="p12x" className="ml-1 my-auto">
        N/A
      </Typography>
    ) : yesNo === false || yesNo?.toString().toLowerCase() === "no" ? (
      <>
        <Icon size="xs" icon="x2" className="my-auto" />
        <Typography type="p12x" className="ml-1 my-auto">
          No
        </Typography>
      </>
    ) : yesNo === true || yesNo?.toString().toLowerCase() === "yes" ? (
      <>
        <Icon size="xs" icon="tick" className="my-auto" />
        <Typography type="p12x" className="ml-1 my-auto">
          Yes
        </Typography>
      </>
    ) : undefined}
  </div>
)

export const IncumbentPill = ({
  incumbent,
  ...props
}: { incumbent: string | boolean } & any) => (
  <YesNoPill {...props} yesNo={incumbent} />
)

const WonPill = ({ won, ...props }: { won: string | boolean } & any) => (
  <YesNoPill {...props} yesNo={won ?? false} />
)

export const CANDIDATE_TABLE_HEADERS: Array<
  TableHeader<Partial<CandidateCampaignInfo>>
> = [
  { name: "Name", key: "name", id: "name", avatarField: "image" },
  { name: "Party", key: PartyPill, id: "party" },
  {
    name: "Gender",
    id: "gender",
    key: ({ gender }): string => (gender ? GENDER[gender] : "N/A"),
    keepIfEmpty: false
  },
  {
    name: "Won",
    id: "won",
    key: WonPill,
    keepIfEmpty: true
  },
  {
    name: "% Votes",
    id: "votePercentage",
    key: ({ votePercentage }) => (votePercentage ? votePercentage + "%" : "0%"),
    keepIfEmpty: true
  }
]

export const PARTY_TABLE_HEADERS: Array<
  TableHeader<Partial<PartyDominanceInfo>>
> = [
  {
    name: "Name",
    key: "name",
    id: "name",
    avatarField: "image",
    keepIfEmpty: false
  },
  { name: "Party", key: PartyPill, id: "party" },
  {
    name: "Won",
    id: "won",
    key: WonPill,
    keepIfEmpty: false
  },
  {
    name: "% Votes",
    id: "votePercentage",
    key: ({ votePercentage }) => (votePercentage ? votePercentage + "%" : "0%"),
    keepIfEmpty: true
  }
]

export function MapPopover<T extends { href?: string }>({
  title,
  rows,
  link,
  onClickClose,
  onClickItem,
  onClickLink,
  linkText,
  emptyMessage = " - No upcoming election",
  tableHeaders = CANDIDATE_TABLE_HEADERS,
  className: extraClassNames = ""
}: MapPopoverProps<T>) {
  return (
    <div
      className={`px-3 py-3 md:opacity-90 w-full bg-white shadow-sm fixed bottom-0 md:absolute left-0 z-[1010] ${extraClassNames}`}
    >
      <div className="flex flex-row justify-between">
        <Typography type="p18" className="text-gray-600 my-auto">
          {rows.length === 0 ? emptyMessage : title}
        </Typography>
        {onClickClose && (
          <div
            onClick={(e) => {
              e.stopPropagation()
              onClickClose()
            }}
            className="bg-neutral-200 h-6 w-6 flex flex-col justify-center cursor-pointer"
          >
            <Icon icon="x" size="xxs" className="m-auto" />
          </div>
        )}
      </div>
      {rows.length === 0 ? null : (
        <>
          <Table
            className="mt-3 max-h-48"
            headers={tableHeaders}
            idField="href"
            rows={rows}
            onClickRow={onClickItem}
          />
          {link && linkText && (
            <Link legacyBehavior href={link}>
              <a onClick={onClickLink}>
                <Typography
                  type="p15x"
                  className="!mt-3 text-accent-600 text-center"
                >
                  {linkText}
                </Typography>
              </a>
            </Link>
          )}
        </>
      )}
    </div>
  )
}

MapPopover.PartyPill = PartyPill
MapPopover.IncumbentPill = IncumbentPill
