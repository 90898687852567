import { byKey } from "@/src/common/utils"

import { TABLE_MAX_CAMPAIGNS } from "../config"
import { ELECTION_NO_RESULT_COLOR } from "../constants"
import { titleFromGeoJSONProperties } from "../selectors"
import {
  CandidateCampaignInfo,
  GeoJSONFeatureProperties,
  PartyDominanceInfo,
  Race,
  RegionSummary
} from "../types"
import { URLFactory } from "./URLFactory"

export class SelectorFactory {
  constructor(
    private readonly race: Race,
    private readonly urlFactory = URLFactory
  ) {}

  public createCampaignSelector(
    campaigns: Array<CandidateCampaignInfo>,
    year: string,
    limit: number | "all" = 2,
    states?: Array<RegionSummary>
  ) {
    const statesByCode = byKey<RegionSummary>(states, "code")

    return ({
      id,
      state,
      constituency,
      lga,
      name
    }: GeoJSONFeatureProperties) => ({
      title: statesByCode[state]?.name
        ? statesByCode[state].name +
          " - " +
          titleFromGeoJSONProperties({ name })
        : titleFromGeoJSONProperties({ name }),

      linkText:
        limit === TABLE_MAX_CAMPAIGNS ? undefined : "View all candidates",

      link: this.urlFactory.election(
        this.race,
        year,
        state,
        lga && this.race === "president"
          ? lga
          : constituency && constituency !== state
          ? constituency
          : undefined
      ),

      rows: campaigns
        .filter(
          ({
            state: campaignState,
            constituency: campaignConstituency,
            lgaCode: campaignLGA
          }) =>
            campaignState === id ||
            campaignConstituency === id ||
            campaignLGA === id
        )
        .slice(0, limit === "all" ? Infinity : limit)
        .map(
          ({
            name,
            slug,
            party,
            incumbent,
            photoURL,
            gender,
            partyColor,
            votePercentage,
            won,
            votes
          }) => ({
            name,
            href: slug ? URLFactory.candidate(slug) : undefined,
            image: photoURL,
            incumbent,
            gender,
            party,
            partyColor,
            votePercentage,
            won,
            votes
          })
        )
    })
  }

  public createCandidateColorPicker(
    candidates: Array<CandidateCampaignInfo>,
    regionLevel: "state" | "lgaCode",
    noResultColor = ELECTION_NO_RESULT_COLOR
  ) {
    const picker: Record<string, CandidateCampaignInfo> = candidates
      .filter(({ won, partyColor }) => won && Boolean(partyColor))
      .reduce(
        (acc, candidate) => ({
          ...acc,
          [candidate[regionLevel] as string]: candidate
        }),
        {}
      )

    picker["FC"] = picker["FCT"]
    return (properties: { state: string; lga?: string }) => {
      const code = properties[regionLevel === "state" ? "state" : "lga"]
      if (!code) {
        console.warn(
          `Cannot find ${regionLevel} in GeoJSON properties: ${properties}`
        )
        return undefined
      }
      return picker[code]?.partyColor || noResultColor
    }
  }

  public createDominantPartyColorPicker(
    parties: Array<PartyDominanceInfo>,
    regionLevel: "stateCode" | "constituencyCode",
    noResultColor = ELECTION_NO_RESULT_COLOR
  ) {
    const picker: Record<string, PartyDominanceInfo> = parties
      .filter(
        ({ won, partyColor, party }) =>
          won && (Boolean(partyColor) || party === "Others")
      )
      .reduce(
        (acc, party) => ({
          ...acc,
          [party[regionLevel] as string]: party
        }),
        {}
      )

    return (properties: { state: string; constituency: string }) => {
      const code =
        properties[regionLevel === "stateCode" ? "state" : "constituency"]
      if (!code) {
        console.warn(
          `Cannot find ${regionLevel} in GeoJSON properties: ${properties}`
        )
        return undefined
      }
      return picker[code]?.partyColor || noResultColor
    }
  }

  public createDominantPartySelector(
    parties: Array<PartyDominanceInfo>,
    year: string,
    limit: number | "all" = 2,
    states?: Array<RegionSummary>,
    hideLosersWhenDataIsIncomplete = false
  ) {
    const statesByCode = byKey<RegionSummary>(states, "code")

    return ({ id, state, constituency, name }: GeoJSONFeatureProperties) => {
      const contestingParties = parties.filter(
        ({
          stateCode: campaignState,
          constituencyCode: campaignConstituency
        }) => campaignState === id || campaignConstituency === id
      )
      const votesMissing = contestingParties.some(
        ({ party, voteCount }) => party !== "Others" && !voteCount
      )
      return {
        title: statesByCode[state]?.name
          ? statesByCode[state].name +
            " - " +
            titleFromGeoJSONProperties({ name })
          : titleFromGeoJSONProperties({ name }),

        linkText:
          limit === TABLE_MAX_CAMPAIGNS ? undefined : "View all parties",

        link: this.urlFactory.election(
          this.race,
          year,
          state,
          constituency !== state ? constituency : undefined
        ),

        rows: contestingParties
          .filter(({ won }) =>
            hideLosersWhenDataIsIncomplete && votesMissing ? won === true : true
          )
          .slice(0, limit === "all" ? Infinity : limit)
          .map(
            ({
              stateCode,
              stateName,
              name,
              candidateName,
              candidateSlug,
              party,
              won,
              partyColor,
              voteCount,
              votes,
              votePercentage,
              image
            }) =>
              ({
                stateCode,
                stateName,
                name,
                candidateName,
                candidateSlug,
                party,
                won,
                partyColor,
                image,
                voteCount,
                votes,
                votePercentage,
                href: candidateSlug
                  ? URLFactory.candidate(candidateSlug)
                  : undefined
              } as PartyDominanceInfo & { href: string })
          )
      }
    }
  }
}
