import { SocialShareCard } from "@/src/common/components/SocialShareCard/index"
import { SocialShareEventProps } from "@/src/elections/business-layer/types"

const DesktopSocialShare = ({
  pageTitle,
  pageURL,
  onClickShare
}: {
  pageTitle: string
  pageURL: string
  onClickShare?: (media: SocialShareEventProps["type"]) => void
}) => (
  <section className="hidden lg:block lg:absolute lg:top-2 lg:-left-14 h-full">
    <div className="lg:sticky lg:top-24">
      <div className="pb-18">
        <SocialShareCard
          title={pageTitle}
          url={pageURL}
          buttons={{ email: false }}
          onClickShare={onClickShare}
          verticalArrangement
        />
      </div>
    </div>
  </section>
)

export default DesktopSocialShare
