import Image from "next/legacy/image"

import defaultAvatar from "../../../../public/assets/img/avatar.svg"

type Size = "xs" | "sm" | "md"
export type AvatarProps = {
  size: Size
  src?: string
  className?: string
}

const SIZE: { [key in Size]: [string, number] } = {
  xs: ["h-4 w-4", 16],
  sm: ["h-5 w-5", 20],
  md: ["h-11 w-11", 44]
}

const Avatar = ({
  size,
  className: extraClassNames,
  src = defaultAvatar
}: AvatarProps) => {
  const [className, dimension] = SIZE[size]
  return (
    <div className={`${className} ${extraClassNames}`}>
      <Image
        src={src}
        alt="Avatar"
        width={dimension}
        height={dimension}
        layout="responsive"
        className="rounded-full"
      />
    </div>
  )
}

export default Avatar
