import { API_BASE_URL } from "@/src/common/config"
import { ElectionsAPI } from "@/src/elections/data-layer"
import { ExternalHTTP } from "@/src/elections/data-layer"
import { ElectionPreference } from "@/src/elections/data-layer/types"
import { useSession } from "next-auth/react"
import { useState } from "react"
import useSWRImmutable from "swr/immutable"

import { ELECTION_PREFERENCES_API_URL } from "../config"

export default function useElectionPreferences(
  externalHTTP: ExternalHTTP,
  electionsAPI: ElectionsAPI
) {
  const { data: session } = useSession()
  const token = (session?.user as any)?.accessToken
  const { data, error, isValidating, mutate } = useSWRImmutable<
    Array<ElectionPreference>
  >(
    token ? [API_BASE_URL + ELECTION_PREFERENCES_API_URL, token] : undefined, // don't bother fetching if there's no token
    externalHTTP.get.bind(externalHTTP),
    {
      refreshInterval: 0
    }
  )
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [mutateError, setMutateError] = useState<string | undefined>()

  const finalToken = token
  const mutatePreferences = (
    updates: Array<ElectionPreference>,
    optimisticData?: Array<ElectionPreference>
  ) =>
    mutate(
      async (_?: Array<ElectionPreference>) => {
        setIsUpdating(true)
        try {
          const updated = await electionsAPI.updatePreferences(
            updates,
            finalToken!
          )
          setIsUpdating(false)
          return updated
        } catch (e: any) {
          setIsUpdating(false)
          console.warn("Failed to update preferences: ", e?.info || e)
          setMutateError(
            "Sorry. It seems we failed to process your request. Please refresh the page and try again"
          )
          // potentially check for 401 error and trigger visibility change, then retry
          // https://github.com/nextauthjs/next-auth/issues/596#issuecomment-943453568
        }
      },
      {
        revalidate: false,
        optimisticData: optimisticData || updates,
        populateCache: true,
        rollbackOnError: true
      }
    )

  const anyError = error || mutateError
  const isLoadingPreferences = token === undefined ? false : !anyError && !data

  return {
    mutatePreferences,
    preferences: data || [],
    loading: isValidating || isLoadingPreferences || isUpdating,
    error: anyError,
    mutate
  }
}
